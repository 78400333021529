import React, { useContext, useEffect, useState } from 'react';
import { Song } from './Song';
import { AppContext } from '../context/AppContext';
import Axios from 'axios';
import { useInterval } from '../hooks/Interval';
import { useTransition, animated } from 'react-spring';

export const RecentList = () => {
  const { appState, appDispatch } = useContext(AppContext);
  const [nextCheck, setNextCheck] = useState(new Date());
  const [isFirstRetrieve, setIsFirstRetrieve] = useState(true);

  const refreshInterval = window.ASC_REFRESH_RATE || 30;

  const getRecents = () => {
    var now = new Date();
    if((isFirstRetrieve || window.isPlaying) && now.getTime() > nextCheck.getTime()) {
      setIsFirstRetrieve(false);
      const ts = new Date().getTime() / 1000;
      var newTs = Math.round(ts / 10);
      const url = window.ASC_RECENT_API_URL.indexOf('?') > 0 ? window.ASC_RECENT_API_URL + "&ts=" + newTs : window.ASC_RECENT_API_URL + "?ts=" + newTs;
      Axios.get(url).then(({ data }) => {
        if (data.songs.length > 0) {
          if(data.songs[0].endTime) {
            setNextCheck(new Date(data.songs[0].endTime));
          }
          appDispatch({
            type: 'UPDATE_LIST',
            currentSong: formatSong(data.songs[0]),
          });
        } else {
          appDispatch({type: 'HIDE_LIST'});
        }
      });
    }
  };

  useEffect(() => {
    getRecents();
  }, []);

  useInterval(getRecents, refreshInterval * 1000);

  const infoTransition = useTransition(
    appState.currentSong,
    (song) => song.songTitle,
    {
      from: { opacity: 0, position: 'absolute' },
      enter: { opacity: 1, position: 'relative' },
      leave: { opacity: 0, position: 'absolute' },
    }
  );

  return appState.apiError || (
    <div className='recently-played-mini-widget'>
      {infoTransition.map(({ item: song, props, key }) => (
        <animated.div className='song-info' key={key} style={props}>
          <span className='now-playing-label'>Now Playing:</span>
          <span className='title'>{song.songTitle}</span>
          <span className='artist'>{song.artist}</span>
        </animated.div>
      ))}
    </div>
  );
};

const formatSong = (song) => {
  return {
    songId: song.id,
    artist: song.artist,
    songTitle: song.title,
    albumTitle: song.album || '',
    albumCover: song.artwork ? song.artwork : undefined,
    startTime: song.startTime,
    endTime: song.endTime,
  };
};
