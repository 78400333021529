import React from 'react';
import styled from 'styled-components';

const ContainerStyle = styled.div`
  padding: 1rem;
`;

export const Container = ({ children }) => (
  <ContainerStyle>{children}</ContainerStyle>
);

const ErrorStyle = styled.div`
  margin: 3rem 0;
  padding: 0 1rem;
  text-align: center;
`;

export const Error = ({ children }) => {
  return (
    <ErrorStyle>
      <h3>An unexpected error occurred...</h3>
      <p>{children}</p>
    </ErrorStyle>
  );
};
