import React, { createContext, useReducer } from 'react';

const initialState = {
  currentSong: {
    songId: '',
    songTitle: '',
    albumCover: '',
    artist: '',
    albumTitle: ''
  },
  apiError: false
};

const AppContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_LIST':
      if (
        action.currentSong && action.currentSong.songId &&
        action.currentSong.songId !== state.currentSong.songId
      ) {
        return {
          ...state,
          currentSong: action.currentSong,
          apiError: false
        };
      } else {
        return {
          ...state,
          apiError: false
        };
      }
    case 'HIDE_LIST':
      return {
        ...state,
        apiError: true
      };
    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const [appState, appDispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ appState, appDispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const AppConsumer = AppContext.Consumer;

export { AppContext, AppProvider, AppConsumer };
