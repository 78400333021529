import React from 'react';
import { AppProvider } from './context/AppContext';
import ErrorWrapper from './components/ErrorWrapper';
import { RecentList } from './components/RecentList';

function App() {
  return (
    <>
      <AppProvider>
        <ErrorWrapper>
          <RecentList />
        </ErrorWrapper>
      </AppProvider>
    </>
  );
}

export default App;
