import React, { Component } from 'react';
import { Error } from '../styles/Common';

class ErrorWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, errorMessage: error.toString() });
  }

  render() {
    if (this.state.hasError) {
      return <Error>{this.state.errorMessage}</Error>
    }
    return this.props.children;
  }
}

export default ErrorWrapper;